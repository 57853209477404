<template>
  <div class="d-flex flex-column">
    <div style="background-color: #f6f6f6; padding: 4px 8px">
      <v-row style="min-height: 64px">
        <!--        <v-col style="position: relative">-->
        <!--          <input-->
        <!--            style="-->
        <!--              background-color: #ffffff;-->
        <!--              padding: 8px 0px 8px 11px;-->
        <!--              outline: none;-->
        <!--              width: 100%;-->
        <!--              border-radius: 21px;-->
        <!--            "-->
        <!--            ref="input"-->
        <!--            type="text"-->
        <!--            placeholder="Search"-->
        <!--            v-model="search"-->
        <!--          />-->
        <!--        </v-col>-->
        <v-col class="d-flex justify-space-around">
          <v-menu offset-y transition="scale-transition">
            <template v-slot:activator="{ on, attrs }" v-if="isAgent">
              <v-btn
                class="chatTopButton"
                color="drawerHover"
                v-bind="attrs"
                v-on="on"
                style="margin-right: 5px"
              >
                {{ filter.title }}
                <v-icon color="white"> mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in filterItems" :key="index" @click="filter = item">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="chatTopButton"
                color="drawerHover"
                v-bind="attrs"
                v-on="on"
                style="margin-right: 5px"
              >
                {{ sort.title }}
                <v-icon color="white"> mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in sortItems" :key="index" @click="sort = item">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="chatTopButton" color="drawerHover" v-bind="attrs" v-on="on">
                <v-icon color="white"> mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in platFormItems"
                :key="index"
                @click="openPlatformDialog(item)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-dialog v-model="showPlatformDialog" width="auto">
            <v-card>
              <v-card-title>
                <div class="mainTitle dialog-header">{{ selectedPlatformItem.title }}</div>
                <v-spacer />
                <v-btn @click="openProfileDialog" v-if="isManager || isAdmin"
                  >Create New Profile</v-btn
                >
              </v-card-title>
              <v-form class="dialog-form">
                <ValidationObserver ref="form">
                  <div class="dialog-content">
                    <div v-if="selectedPlatformItem.value === 'wabiz'">
                      <ValidationProvider v-slot="{ errors }" name="Account" rules="required">
                        <v-autocomplete
                          label="Account"
                          chips
                          small-chips
                          v-model="account"
                          :items="accountList"
                          :item-text="item => profileDisplayName(item.senderIdentity, true)"
                          item-value="_id"
                          return-object
                          outlined
                          :error="!!errors.length"
                          :error-messages="errors"
                        ></v-autocomplete>
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ errors }" name="Receiver" rules="required">
                        <Autocomplete
                          :path="'im/admin/profile/multipleAutocomplete'"
                          :params="{ filter: { _id: computedReceiver } }"
                          :item-text="item => profileDisplayName(item, true)"
                          :data="receiver"
                          item-value="_id"
                          v-model="receiver"
                          label="To: "
                          clearable
                          chips
                          small-chips
                          return-object
                          outlined
                          multiple
                          :error="!!errors.length"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        v-if="account"
                        v-slot="{ errors }"
                        name="Template"
                        rules="required"
                      >
                        <v-autocomplete
                          label="Template"
                          v-model="template"
                          :items="templateList"
                          :item-text="item => item.name + ' (' + item.category + ')'"
                          item-value="id"
                          return-object
                          outlined
                          :error="!!errors.length"
                          :error-messages="errors"
                        >
                        </v-autocomplete>
                      </ValidationProvider>
                      <template v-if="template">
                        <div class="d-flex justify-center">
                          <v-card width="100%">
                            <span v-for="(item, index) in template.components" :key="index">
                              <v-card-text
                                v-if="item.text"
                                :class="item.type === 'HEADER' ? 'text-h6' : 'text-body-1'"
                              >
                                {{ item.text }}
                              </v-card-text>
                            </span>
                          </v-card>
                        </div>
                      </template>
                      <template v-if="templateParams.length > 0">
                        <ValidationProvider
                          v-for="(item, index) in templateParams"
                          :key="index"
                          v-slot="{ errors }"
                          :name="item.text"
                          rules="required"
                        >
                          <v-text-field
                            :label="String(index + 1)"
                            v-model="item.text"
                            :error="!!errors.length"
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </template>
                    </div>
                  </div>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn @click="showPlatformDialog = false">Cancel</v-btn>
                    <v-btn @click="sendPlatformMessage" color="success">Send</v-btn>
                  </v-card-actions>
                </ValidationObserver>
              </v-form>
            </v-card>
            <ProfileDialog
              v-model="showProfileDialog"
              @submit="createNewClientProfile"
            ></ProfileDialog>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
    <v-list class="scrollable flex-grow-1">
      <template v-for="x in chats">
        <ChatControllerConsumer :chatId="x._id" v-slot="{ chat, controller }" :key="x._id">
          <ChatListItem
            :inputValue="selectedChatId === x._id"
            @click="$emit('update:selectedChatId', x._id)"
            :chat="chat"
            :lastMessage="controller && controller.lastMessage"
          />
        </ChatControllerConsumer>
      </template>
      <v-list-item key="1" v-if="loading">
        <v-list-item-content>
          <v-progress-circular indeterminate color="primary" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item key="2" v-else-if="hasMore" v-intersect="{ handler: onIntersect }">
        <v-list-item-content>
          <v-progress-circular indeterminate color="primary" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item key="3" v-else>
        <v-list-item-content>
          <div class="text--secondary" style="text-align: center; font-size: smaller">
            - no more -
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import ChatControllerConsumer from '@/components/Chat/parts/ChatControllerConsumer';
import ChatListItem from '@/components/Chat/parts/ChatListItem';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import api from '@/api';
import { readWhatsappBizTemplate, sendWhatsappBizMessage } from '@/api/chat';
import { createClientProfile } from '@/api/auth/membership';
import { listGateways } from '@/api/gw';
import { profileDisplayName } from '@/services/filters.js';
import Autocomplete from '@/components/Autocomplete';
import ProfileDialog from '@/components/Chat/parts/ProfileDialog';

export default {
  name: 'ChatList',
  components: { ChatControllerConsumer, ChatListItem, Autocomplete, ProfileDialog },
  props: {
    selectedChatId: String,
  },
  inject: ['chatManager'],
  data() {
    return {
      search: null,
      filter: { title: 'All', value: null },
      filterItems: [
        { title: 'All', value: null },
        {
          title: 'Not Assigned',

          value: { '_assignedAgents.0': { $exists: false } },
        },
        { title: 'Assigned', value: { '_assignedAgents.0': { $exists: true } } },
        // { title: 'Archived', value: { enabled: false } },
      ],
      sort: { title: 'New', value: { updated_at: -1 } },
      sortItems: [
        { title: 'New', value: { updated_at: -1 } },
        { title: 'Old', value: { updated_at: 1 } },
      ],
      platFormItems: [{ title: 'init Whatsapp Business message', value: 'wabiz' }],
      selectedPlatformItem: {},
      showPlatformDialog: false,
      // platform dialog
      account: null,
      accountList: [],
      receiver: [],
      template: null,
      templateParams: [],
      templateList: [],
      searching: false,
      /** @type {IChat[]} */
      chats: [],
      hasMore: true,
      loading: false,
      debouncedReload: debounce(this.reload, 300),
      showProfileDialog: false,
    };
  },
  watch: {
    computedQuerySerialized: {
      immediate: false,
      handler() {
        this.debouncedReload();
      },
    },
    showPlatformDialog(val) {
      // clear all selected value and reset form when close the dialog
      if (!val) {
        this.$refs?.form?.reset();
        this.clearPlatformMessage();
      }
    },
    selectedPlatformItem(item) {
      this.showAccountList(item.value);
    },
    account(item) {
      this.templateList = [];
      this.template = null;
      this.showTemplateList(item);
      this.templateParams = [];
    },
    template(v) {
      if (v) {
        this.calculateTemplateParameters(v);
      }
    },
  },
  mounted() {
    this.chatManager.$on('updateChat', this.refresh);
    this.chatManager.$on('removeChat', this.removeChat);
    this.chatManager.$on('reloadChats', this.refresh);
  },
  destroyed() {
    this.chatManager.$off('updateChat', this.refresh);
    this.chatManager.$off('removeChat', this.removeChat);
    this.chatManager.$off('reloadChats', this.refresh);
  },
  computed: {
    ...mapGetters('auth', ['role']),
    ...mapActions('alert', ['updateAlertMessage']),
    computedQuery() {
      return {
        sort: this.sort?.value,
        filter: this.filter?.value,
        search: this.search || undefined,
      };
    },
    computedQuerySerialized() {
      return JSON.stringify(this.computedQuery);
    },
    isAgent() {
      return this.role !== 'agent';
    },
    isManager() {
      return this.role === 'manager';
    },
    isAdmin() {
      return this.role === 'admin';
    },
    computedReceiver() {
      return this.receiver && this.receiver.length > 0 ? this.receiver.map(x => x?._id) : null;
    },
  },
  methods: {
    profileDisplayName,
    async reload() {
      if (this.loading) return;
      try {
        this.loading = true;
        const { docs, total, offset } = await this.chatManager.listChats({
          ...this.computedQuery,
          select: '_id',
        });
        this.chats = docs;
        this.hasMore = total > offset + docs.length;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async loadMore() {
      if (this.loading) return;
      try {
        this.loading = true;
        const { docs, total, offset } = await this.chatManager.listChats({
          ...this.computedQuery,
          select: '_id',
          offset: this.chats.length,
        });
        this.chats = this.chats.concat(docs);
        this.hasMore = total > offset + docs.length;
      } catch (e) {
        this.hasMore = false;
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      if (this.loading) return;
      try {
        this.loading = true;
        const { docs, total, offset } = await this.chatManager.listChats({
          ...this.computedQuery,
          select: '_id',
          offset: 0,
          limit: this.chats.length + 1,
        });
        this.chats = docs;
        this.hasMore = total > offset + docs.length;
      } finally {
        this.loading = false;
      }
    },
    onIntersect(e) {
      if (!this.loading && this.hasMore && e[0]?.isIntersecting) {
        this.loadMore();
      }
    },
    removeChat(id) {
      this.chats = this.chats.filter(x => x._id !== id);
    },
    openPlatformDialog(item) {
      this.showPlatformDialog = true;
      this.selectedPlatformItem = item;
    },
    async showAccountList(platform) {
      try {
        const docs = (
          await listGateways(api, {
            filter: { platform: platform },
            limit: 300,
            populate: JSON.stringify({
              path: 'senderIdentity',
            }),
          })
        ).docs;
        this.accountList = docs;
      } catch (error) {
        console.log(error);
      }
    },
    async showTemplateList(account) {
      if (account) {
        try {
          const res = await readWhatsappBizTemplate(api, account.waba_id, {
            limit: 99,
            accessToken: account.accessToken,
          });
          this.templateList = res?.data || [];
        } catch (error) {
          console.log(error);
        }
      }
    },
    calculateTemplateParameters(v) {
      this.templateParams.splice(0);
      const templateBody = v.components.find(b => b.type === 'BODY');
      if (templateBody.text) {
        const paramsArr = templateBody.text.match(/{{\d+}}/g) || [];
        for (var j = 0; j < paramsArr.length; j++) {
          this.$set(this.templateParams, j, { type: 'text', text: '' });
        }
      }
    },
    async sendPlatformMessage() {
      if (!(await this.$refs.form?.validate?.())) return;
      const gateway = this.account;
      const templateArgs = {
        name: this.template?.name,
        language: {
          code: this.template?.language,
        },
        components: [
          {
            type: 'body',
            parameters: this.templateParams,
          },
        ],
      };
      const sentMessage = this.getMessageFromTemplate(
        this.template.components,
        this.templateParams,
      );
      try {
        await sendWhatsappBizMessage(api, {
          gateway: gateway,
          receiver: this.receiver,
          template: templateArgs,
          message: sentMessage,
        });
        this.alertMessage('success');
        this.showPlatformDialog = false;
      } catch (error) {
        this.alertMessage('', error);
      }
    },
    openProfileDialog(query) {
      this.showProfileDialog = true;
    },
    async createNewClientProfile(clientProfile) {
      try {
        if (clientProfile?.phone?.substring(0, 3) !== '852')
          clientProfile.phone = `852${clientProfile?.phone}`;
        const result = await createClientProfile(api, { ...clientProfile, role: 'client' });
        this.receiver.push(result);
        this.alertMessage('success', 'Successfully created client profile');
      } catch (e) {
        this.alertMessage('failed', e);
      }
    },
    clearPlatformMessage() {
      this.account = null;
      this.receiver = [];
      this.templateList = [];
      this.template = null;
      this.templateParams = [];
    },
    getMessageFromTemplate(component, params) {
      const body = component.find(x => x.type === 'BODY');
      params.map((x, i) => {
        body.text = body.text.replace(`{{${i + 1}}}`, x.text);
      });

      return component.map(x => x.text).join('\n');
    },
    alertMessage(type, msg = 'sent') {
      if (type === 'success') {
        return this.$store.dispatch('alert/updateAlertMessage', {
          msg: msg,
          type: 'success',
          color: 'success',
        });
      } else {
        return this.$store.dispatch('alert/updateAlertMessage', {
          msg: msg,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app .chatTopButton {
  color: white !important;
}

.scrollable {
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-y: auto;
}

.dialog-form {
  padding: 10px 23px;
}

.dialog-content {
  height: 70vh;
  overflow-y: scroll;
}

.dialog-header {
  font-size: 20px;
}
</style>
