import { render, staticRenderFns } from "./ChatListItem.vue?vue&type=template&id=1d9b8445&scoped=true&"
import script from "./ChatListItem.vue?vue&type=script&lang=js&"
export * from "./ChatListItem.vue?vue&type=script&lang=js&"
import style0 from "./ChatListItem.vue?vue&type=style&index=0&id=1d9b8445&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d9b8445",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle})
