<template>
  <v-container class="d-flex">
    <template v-if="!!chat">
      <template v-if="isAdmin || isManager">
        <v-menu offset-y v-if="switchSender">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" :disabled="isAgent">
              {{ switchSender | profileDisplayName }}
              <v-icon v-if="currentPlatform === 'chatapi'"> mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list v-if="currentPlatform === 'chatapi'">
            <v-list-item
              v-for="(item, index) in items.docs"
              :key="index"
              :inputValue="oidEquals(item, switchSender)"
              @click="changeApi(item)"
            >
              <v-list-item-title>{{ item | profileDisplayName(true) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon>mdi-arrow-right</v-icon>
      </template>
      <v-btn
        text
        :to="{ name: 'Profile', params: { id: currentRecipient._id } }"
        v-if="currentRecipient"
      >
        {{ currentRecipient | profileDisplayName(true) }}
      </v-btn>
      <v-spacer />
      <template v-if="envChatGPT && isAdmin">
        <div>
          ChatGPT:
          <v-switch
            v-model="chat.enabledChatGPT"
            class="mt-0 pt-0 ml-3"
            hide-details
            @change="v => confirmToggleChatGPT(v)"
          />
        </div>
        <v-spacer />
        <div>
          <v-btn color="error" class="mr-2" @click="promptClearChatGPTCache">
            clear ChatGPT Cache
          </v-btn>
        </div>
        <v-spacer />
      </template>
      <v-btn v-if="!isAgent" class="mr-2" color="info" @click="$emit('showAssignAgentDialog')">
        Assign Agent
      </v-btn>
      <v-btn color="info" class="mr-2 track" @click="$emit('showActivityDialog')">
        Activity Track
      </v-btn>
      <v-btn v-if="debug && isAdmin" color="error" class="mr-2" @click="promptDeleteChat">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <custom-dialog ref="confirm">
        <template #message="{ message }">
          <v-card-text class="pa-4 headline">{{ message }}</v-card-text>
        </template>
      </custom-dialog>
    </template>
  </v-container>
</template>

<script>
import api from '@/api';
import { clearChatGPTCache, IChat } from '@/api/chat';
import { crudDelete, crudList, crudUpdate } from '@/api/crud';
import CustomDialog from '@/components/CustomDialog';
import { getOid, oidEquals } from '@/services/utils';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'MessageListToolbar',
  components: {
    CustomDialog,
  },
  props: {
    chat: IChat,
    switchSender: null,
  },
  inject: ['chatManager'],
  data() {
    return {
      items: {},
    };
  },
  watch: {
    'chat._id': {
      immediate: true,
      handler(v) {
        if (v) {
          this.$emit('update:switchSender', this.currentSender);
        }
      },
    },
    isCurrentSender() {
      this.trySwitchToRealChat();
    },
    currentPlatform: {
      immediate: true,
      handler() {
        this.reloadSenders();
      },
    },
  },
  mounted() {
    this.chatManager.$on('reloadChats', this.onReloadChats);
  },
  destroyed() {
    this.chatManager.$off('reloadChats', this.onReloadChats);
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isAgent', 'role', 'isManager']),
    ...mapGetters(['debug']),
    currentSender() {
      return this.chat?.sender;
    },
    isCurrentSender() {
      return oidEquals(this.currentSender, this.switchSender);
    },
    currentRecipient() {
      return this.chat?.participants?.find(x => !x.isSender);
    },
    currentPlatform() {
      return this.chat?.platform;
    },
    envChatGPT() {
      return process.env.VUE_APP_CHATGPT;
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    oidEquals,
    onReloadChats() {
      this.trySwitchToRealChat();
    },
    changeApi(item) {
      this.$emit('update:switchSender', item);
    },
    async reloadSenders() {
      if (this.isAdmin || this.isManager) {
        try {
          this.items = await crudList(api, 'im/admin/profiles', {
            filter: { isSender: true, ['metadata.' + this.currentPlatform]: { $exists: true } },
          });
        } catch (err) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: err,
            type: 'error',
          });
        }
      }
    },
    async promptDeleteChat() {
      if (
        !(await this.$refs.confirm.open(
          'Delete Chat',
          'Are you sure to permanently delete chat? This cannot be undone.',
          { color: 'error' },
        ))
      ) {
        return;
      }
      try {
        await crudDelete(api, 'im/admin/chats', this.chat._id);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Deleted',
          type: 'success',
          color: 'success',
        });
        this.$router.replace({ name: 'Chats' }).catch(() => {});
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
          type: 'error',
        });
      }
    },
    async confirmToggleChatGPT(v) {
      if (
        !(await this.$refs.confirm.open(
          'ChatGPT',
          `Are you sure to ${v ? 'enable' : 'disable'} ChatGPT?`,
          { color: 'error' },
        ))
      ) {
        return;
      }
      try {
        await crudUpdate(api, 'im/admin/chats', this.chat._id, { enabledChatGPT: v });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'updated',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
          type: 'error',
        });
      }
    },
    async promptClearChatGPTCache() {
      if (
        !(await this.$refs.confirm.open(
          'Delete ChatGPT Cache',
          'Are you sure to permanently delete ChatGPT Cache? This cannot be undone.',
          { color: 'error' },
        ))
      ) {
        return;
      }
      await clearChatGPTCache(api, this.chat._id);
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Deleted',
        type: 'success',
        color: 'success',
      });
    },
    async trySwitchToRealChat() {
      if (!this.isCurrentSender) {
        const target = getOid(this.switchSender);
        const rcpt = getOid(this.currentRecipient);
        const { docs } = await this.chatManager.listChats({
          filter: {
            _id: { $ne: this.chat?._id },
            senderIdentity: target,
            participants: rcpt,
            platform: this.chat?.platform,
          },
          limit: 1,
        });
        if (docs[0]) {
          this.$emit('switchToChat', getOid(docs[0]));
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.track {
  color: white;
}
</style>
