<template>
  <v-container class="pa-3">
    <div style="max-width: 600px" class="d-flex justify-space-between mb-3">
      <span style="align-self: center" class="mainTitle">My Profile</span>
      <v-btn @click="changePassword" style="align-items: center">Reset Password</v-btn>
    </div>
    <v-card class="addCardBorderColor" elevation="4" width="600" v-if="profile">
      <v-card-title title="Name">
        <v-icon> mdi-account-circle </v-icon>
        <span style="font-size: 20px">
          {{ profile.name }}
        </span>
      </v-card-title>
      <v-card-subtitle title="Role">
        <span class="textLabel">
          {{ profile.role }}
        </span>

        <v-divider></v-divider>
      </v-card-subtitle>
      <v-card-text>
        <p>
          <v-icon title="Email"> mdi-email </v-icon>
          <span class="textLabel">
            {{ profile.email }}
          </span>
        </p>
        <p>
          <v-icon alt="Phone" title="Phone"> mdi-phone </v-icon>
          <span class="textLabel">
            {{ displayPhone(profile.phone, x => x.formatInternational()) || 'No Contact' }}
          </span>
        </p>
        <p>
          <v-icon title="Created at"> mdi-calendar-account </v-icon>
          <span class="textLabel">
            {{ profile.created_at | moment('YYYY-MM-DD') }}
          </span>
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { displayPhone } from '@/services/filters';
import { mapActions, mapGetters } from 'vuex';
import { forgetPassword } from '@/api/auth/sso';
import api from '@/api';

export default {
  name: 'MyProfile',
  computed: {
    ...mapGetters('auth', ['profile']),
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    displayPhone,
    async changePassword() {
      try {
        await forgetPassword(api, { email: this.profile.email });
        this.updateAlertMessage({
          msg: 'An email has been sent. Please check your email.',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        this.updateAlertMessage({
          msg: err.message,
        });
      }
    },
  },
};
</script>

<style scoped>
.addCardBorderColor {
  border: 5px solid var(--v-borderColor-base);
  border-radius: 20px;
}
</style>
