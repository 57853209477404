<template>
  <SocketManager>
    <ChatsManager @assignedChats="onAssignedChats">
      <v-container fluid class="root">
        <v-row no-gutters>
          <v-col sm="3" v-show="$vuetify.breakpoint.smAndUp || !selectedChatId">
            <ChatList
              style="height: 100%"
              :selectedChatId="selectedChatId"
              @update:selectedChatId="navToChat"
            />
            <custom-dialog ref="confirm" />
          </v-col>
          <v-col sm="9" v-show="$vuetify.breakpoint.smAndUp || selectedChatId">
            <ChatControllerConsumer
              :chatId="selectedChatId"
              v-slot="{ controller, chat }"
              @removeChat="alertSelectedChatRemoved"
            >
              <ChatPanel
                v-if="controller"
                :controller="controller"
                :chat="chat"
                @switchToChat="id => navToChat(id)"
              />
              <div class="messagePlaceholder" v-else>
                <div>
                  <p class="IMBiz">IM BIZ</p>
                  <p style="font-size: 30px">
                    Select A Chat
                    <v-icon large>mdi-message-text</v-icon>
                  </p>
                </div>
              </div>
            </ChatControllerConsumer>
          </v-col>
        </v-row>
      </v-container>
    </ChatsManager>
  </SocketManager>
</template>

<script>
import ChatControllerConsumer from '@/components/Chat/parts/ChatControllerConsumer';
import ChatList from '@/components/Chat/parts/ChatList';
import ChatPanel from '@/components/Chat/parts/ChatPanel';
import ChatsManager from '@/components/Chat/parts/ChatsManager';
import SocketManager from '@/components/Chat/parts/SocketManager';
import { oidEquals } from '@/services/utils';
import { mapActions, mapGetters } from 'vuex';
import CustomDialog from '../CustomDialog';

export default {
  components: {
    CustomDialog,
    SocketManager,
    ChatsManager,
    ChatList,
    ChatControllerConsumer,
    ChatPanel,
  },
  data() {
    return {
      totalChatHeight: 0,
    };
  },
  props: {
    selectedChatId: String,
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isAgent', 'role']),
  },
  methods: {
    oidEquals,
    ...mapActions('alert', ['updateAlertMessage']),
    navToChat(id) {
      if (this.selectedChatId) {
        this.$router.replace({ name: 'Chat', params: { id } }).catch(() => {});
      } else {
        this.$router.push({ name: 'Chat', params: { id } }).catch(() => {});
      }
    },
    alertSelectedChatRemoved() {
      if (this.role === 'agent') {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'This chat has been re-assigned to another agent. You can no longer enter the chat again.',
        });
      } else if (this.role === 'manager') {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Your team has been blocked from all chats. You can no longer enter the chats again.',
        });
      }
      if (this.selectedChatId) {
        this.navToChat(undefined);
      }
    },
    /**
     * @param {string[]} ids
     * @param {string[]} added
     * @param {string[]} removed
     */
    async onAssignedChats(ids, { added, removed }) {
      if (added[0]) {
        const confirm = await this.$refs.confirm.open(
          'New Client',
          'A new client has been assigned to you! Click [OK] to view the chat',
          {
            color: 'success',
            width: '40%',
            confirmBtn: 'OK',
          },
        );
        if (confirm) {
          this.navToChat(added[0]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messagePlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  background-color: var(--v-backgroundColor-base);

  > div {
    text-align: center;
  }
}

.IMBiz {
  font-size: 90px;
  letter-spacing: 4px;
  font-weight: bold;
}

.root {
  padding: 0;
  height: 100%;

  .row,
  .col,
  .col > * {
    height: 100%;
  }
}
</style>
