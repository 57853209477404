<template>
  <v-dialog v-model="valueProxy">
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card>
        <v-card-title> Create Profile </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col :md="6">
                <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
                  <v-text-field
                    label="Name: "
                    outlined
                    v-model="model.name"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :md="6">
                <ValidationProvider v-slot="{ errors }" name="Phone" rules="required">
                  <v-text-field
                    label="Phone: "
                    outlined
                    v-model="model.phone"
                    :error="!!errors.length"
                    :error-messages="errors"
                  >
                    <!--
                    <template v-slot:prepend-inner>
                      <label class="v-label" style="left: 0px; right: auto; min-width: 50px"
                        >+852</label
                    />
                    -->
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="doClose">cancel</v-btn>
          <v-btn @click="submit" color="success" :disabled="invalid">submit</v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import editDialogMixin from '@/services/editDialogMixin';

export default {
  name: 'ProfileDialog',
  mixins: [editDialogMixin],
  methods: {
    reload() {
      this.$refs?.form?.reset();
      this.model = {};
    },
    async submit() {
      if (!(await this.$refs.form.validate())) return;
      this.$emit('submit', this.model);
      this.doClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>