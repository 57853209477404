<template>
  <v-list-item :inputValue="inputValue" v-on="$listeners" v-bind="$attrs">
    <v-list-item-content>
      <v-list-item-title>
        <template v-if="chat">
          <span v-if="renderChatTitle">{{ renderChatTitle }}</span>
          <i class="text--disabled" v-else>&lt; anonymous &gt;</i>
        </template>
        <div v-else class="placeholder" style="width: 100px">&nbsp;</div>
      </v-list-item-title>
      <v-list-item-subtitle>
        <template v-if="chat">
          <span v-if="renderChatSubtitle">{{ renderChatSubtitle }}</span>
          <i class="text--disabled" v-else>no messages</i>
        </template>
        <div v-else class="placeholder" style="width: 200px">&nbsp;</div>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="renderChatDate">
      <span style="font-size: 12px">{{ renderChatDate }}</span>
    </v-list-item-action>
  </v-list-item>
</template>

<style lang="scss" scoped>
.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
</style>

<script>
import { IChat, IChatMessage } from '@/api/chat';
import { profileDisplayName } from '@/services/filters';
import moment from 'moment';

export default {
  name: 'ChatListItem',
  props: {
    inputValue: Boolean,
    chat: IChat,
    lastMessage: IChatMessage,
  },
  computed: {
    renderChatDate() {
      if (this.chat) {
        const m = this.chat.updated_at;
        const today = moment(m).isSame(new Date(), 'day');
        if (today) {
          return moment(m).format('A h:mm');
        } else return moment(m).format('dddd');
      }
    },
    renderChatSubtitle() {
      const m = this.lastMessage;
      if (!m) return;
      const author = m?.author && (m.author.isSender ? 'Me' : profileDisplayName(m.author, false));
      const text =
        m.type === 'image'
          ? '📷 Image'
          : m.type === 'video'
          ? '📹 Video'
          : m.type === 'audio'
          ? '🔉 Audio'
          : m.type === 'document'
          ? `📎 ${m.metadata?.caption || 'File'}`
          : m.type === 'ptt'
          ? '🎤 Voice'
          : m.type === 'location'
          ? '📍 Location'
          : // else text
            m.body;
      return `${author}: ${text}`;
    },
    renderChatTitle() {
      const m = this.chat?.participants.find(x => !x.isSender);
      return m && profileDisplayName(m, false);
    },
  },
};
</script>

<style scoped></style>
