<template>
  <v-autocomplete
    class="autoCompleted"
    v-bind="$attrs"
    :items="list"
    v-on="$listeners"
    :search-input.sync="search"
    :value="value"
    @change="reload"
    :disabled="disabled"
    :menu-props="{ offsetY: true }"
    no-filter
    append-icon="mdi-magnify"
  >
    <template v-if="enableCreate" v-slot:no-data>
      <div @click="createNew">Create New "{{ search }}"</div>
    </template>
    <template v-slot:append-item>
      <div v-intersect="onIntersect" />
    </template>
  </v-autocomplete>
</template>

<script>
import { crudList } from '@/api/crud';
import api from '@/api';
import { debounce } from 'lodash';

export default {
  name: 'Autocomplete',
  props: {
    path: {},
    value: {},
    params: {},
    disabled: { type: Boolean, default: false },
    enableCreate: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
  },
  data() {
    return {
      list: [],
      search: null,
      page: 0,
      totalPages: 1,
      debounceToGetData: debounce(this.reload, 500),
    };
  },
  computed: {
    computedInputChange() {
      return `${this.search}|${this.path}|${this.params}`;
    },
  },
  watch: {
    computedInputChange: {
      immediate: true,
      deep: true,
      handler(v) {
        this.debounceToGetData();
      },
    },
  },
  methods: {
    async reload() {
      this.page = 0;
      let filterParams = this.params;
      if (!filterParams?.filter?._id) delete filterParams.filter;
      const res = await crudList(api, this.path, {
        search: this.search,
        ...filterParams,
      });
      this.totalPages = res.pages;
      this.list = this.data ? this.data.concat(res.docs) : res.docs;
    },
    async onIntersect() {
      if (this.page <= this.totalPages) {
        const moreListItems = (
          await crudList(api, this.path, {
            search: this.search,
            page: ++this.page,
            ...this.params,
          })
        ).docs;
        this.list = this.list.concat(moreListItems);
      }
    },
    createNew() {
      this.$emit('autocomplete_create_new', this.search);
      this.search = null;
      this.reload();
    },
  },
};
</script>

<style lang="scss">
.autoCompleted .v-icon {
  transform: rotate(0deg) !important;
}
</style>
