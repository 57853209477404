var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticStyle:{"background-color":"#f6f6f6","padding":"4px 8px"}},[_c('v-row',{staticStyle:{"min-height":"64px"}},[_c('v-col',{staticClass:"d-flex justify-space-around"},[_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([(_vm.isAgent)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"chatTopButton",staticStyle:{"margin-right":"5px"},attrs:{"color":"drawerHover"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.filter.title)+" "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-down")])],1)]}}:null],null,true)},[_c('v-list',_vm._l((_vm.filterItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.filter = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"chatTopButton",staticStyle:{"margin-right":"5px"},attrs:{"color":"drawerHover"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.sort.title)+" "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.sortItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.sort = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"chatTopButton",attrs:{"color":"drawerHover"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.platFormItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.openPlatformDialog(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-dialog',{attrs:{"width":"auto"},model:{value:(_vm.showPlatformDialog),callback:function ($$v) {_vm.showPlatformDialog=$$v},expression:"showPlatformDialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"mainTitle dialog-header"},[_vm._v(_vm._s(_vm.selectedPlatformItem.title))]),_c('v-spacer'),(_vm.isManager || _vm.isAdmin)?_c('v-btn',{on:{"click":_vm.openProfileDialog}},[_vm._v("Create New Profile")]):_vm._e()],1),_c('v-form',{staticClass:"dialog-form"},[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"dialog-content"},[(_vm.selectedPlatformItem.value === 'wabiz')?_c('div',[_c('ValidationProvider',{attrs:{"name":"Account","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Account","chips":"","small-chips":"","items":_vm.accountList,"item-text":function (item) { return _vm.profileDisplayName(item.senderIdentity, true); },"item-value":"_id","return-object":"","outlined":"","error":!!errors.length,"error-messages":errors},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}})]}}],null,false,3775599984)}),_c('ValidationProvider',{attrs:{"name":"Receiver","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Autocomplete',{attrs:{"path":'im/admin/profile/multipleAutocomplete',"params":{ filter: { _id: _vm.computedReceiver } },"item-text":function (item) { return _vm.profileDisplayName(item, true); },"data":_vm.receiver,"item-value":"_id","label":"To: ","clearable":"","chips":"","small-chips":"","return-object":"","outlined":"","multiple":"","error":!!errors.length,"error-messages":errors},model:{value:(_vm.receiver),callback:function ($$v) {_vm.receiver=$$v},expression:"receiver"}})]}}],null,false,568799165)}),(_vm.account)?_c('ValidationProvider',{attrs:{"name":"Template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Template","items":_vm.templateList,"item-text":function (item) { return item.name + ' (' + item.category + ')'; },"item-value":"id","return-object":"","outlined":"","error":!!errors.length,"error-messages":errors},model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}})]}}],null,false,2543166106)}):_vm._e(),(_vm.template)?[_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{attrs:{"width":"100%"}},_vm._l((_vm.template.components),function(item,index){return _c('span',{key:index},[(item.text)?_c('v-card-text',{class:item.type === 'HEADER' ? 'text-h6' : 'text-body-1'},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()],1)}),0)],1)]:_vm._e(),(_vm.templateParams.length > 0)?_vm._l((_vm.templateParams),function(item,index){return _c('ValidationProvider',{key:index,attrs:{"name":item.text,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":String(index + 1),"error":!!errors.length,"error-messages":errors},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}})]}}],null,true)})}):_vm._e()],2):_vm._e()]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.showPlatformDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.sendPlatformMessage}},[_vm._v("Send")])],1)],1)],1)],1),_c('ProfileDialog',{on:{"submit":_vm.createNewClientProfile},model:{value:(_vm.showProfileDialog),callback:function ($$v) {_vm.showProfileDialog=$$v},expression:"showProfileDialog"}})],1)],1)],1)],1),_c('v-list',{staticClass:"scrollable flex-grow-1"},[_vm._l((_vm.chats),function(x){return [_c('ChatControllerConsumer',{key:x._id,attrs:{"chatId":x._id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var chat = ref.chat;
var controller = ref.controller;
return [_c('ChatListItem',{attrs:{"inputValue":_vm.selectedChatId === x._id,"chat":chat,"lastMessage":controller && controller.lastMessage},on:{"click":function($event){return _vm.$emit('update:selectedChatId', x._id)}}})]}}],null,true)})]}),(_vm.loading)?_c('v-list-item',{key:"1"},[_c('v-list-item-content',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):(_vm.hasMore)?_c('v-list-item',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}],key:"2"},[_c('v-list-item-content',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_c('v-list-item',{key:"3"},[_c('v-list-item-content',[_c('div',{staticClass:"text--secondary",staticStyle:{"text-align":"center","font-size":"smaller"}},[_vm._v(" - no more - ")])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }